@import "angular-material-theme";

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
}

.page-container {
    max-width: 1072px;
    margin: 0 auto;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
}